<template>
  <div class="container">
    <nav-top
      :list="nav.list"
      :current-key="nav.currentKey"
      />

    <ul class="offer-list" ref="container">
      <template v-for="(item, itemKey) in nav.list">
        <template v-if="nav.currentKey === itemKey">
          <template v-if="item.type === 'offer'">
            <template v-if="!init || listData[nav.currentKey].length">
              <template v-for="(row, key) in listData[nav.currentKey]">
                <o-item :key="`${itemKey}:${key}`" :row="row" :no-paid-offer="nav.list[nav.currentKey].noPaidOffer" />
                <div class="ui-border-line" :key="`${itemKey}:${key}:line`" style="height: 10px;"></div>
              </template>
            </template>
            <blank-list :key="itemKey" v-else :text="item.emptyText" />
          </template>
          <!-- <template v-else>
            <template v-if="!init || listData[nav.currentKey].length">
              <template v-for="(row, key) in listData[nav.currentKey]">
                <a-item :key="`${itemKey}:${key}`" :row="row" />
                <div :key="`${itemKey}:${key}:border`" class="ui-border-line" />
              </template>
            </template>
            <blank-list :key="itemKey" v-else :text="item.emptyText" />
          </template> -->
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
import type { IClientMyOfferNavItem } from '@/flow/index.js'
import OItem from '@/components/client/OItem'
import AItem from '@/components/artist/AItem'
import BlankList from '@/components/blank/BlankList'
import NavTop from '@/components/common/NavTop'

export default {
  name: 'ClientMyOffer',
  components: {
    OItem,
    AItem,
    BlankList,
    NavTop,
  },
  data() {
    let navBtnCb = (key = 0) => {
      this.que.listData = this.que.listData.map(() => false)
      this.scroll.lastY[this.nav.currentKey] = this.$refs.container.scrollTop
      this.nav.currentKey = key

      this.$router.replace({
        query: {
          nav: key,
        }
      })
      this.$nextTick()
        .then(() => {
          this.$refs.container.scrollTop = this.scroll.lastY[this.nav.currentKey]
          this.getData(this.listData[this.nav.currentKey].length === 0)
        })
    }

    const nav: {
      list: IClientMyOfferNavItem[];
      currentKey: number;
    } = {
      list: [
        {
          label: '등록한 의뢰',
          action: () => navBtnCb(0),
          nowState: 'ing,cancel,wait,selfEnd,autoEnd,end,partCancel',
          type: 'offer',
          emptyText: `아직 등록한 의뢰가 없으시네요:)\n원하는 조건 등록하고\n맞춤 견적을 받아보세요!`,
          noPaidOffer: true,
        },
        // {
        //   label: '관심 목록',
        //   action: () => navBtnCb(1),
        //   nowState: '',
        //   type: 'artist',
        //   emptyText: `마음에 드는 전문가가 있다면?\n언제나 빠르게 볼 수 있도록\n'좋아요'를 누르세요!`,
        // },
        {
          label: '결제 내역',
          action: () => navBtnCb(1),
          nowState: 'end,cancel,cancelRequest,pay,payRequest,partCancel',
          type: 'offer',
          emptyText: `아직 등록한 의뢰가 없으시네요:)\n원하는 조건 등록하고\n맞춤 견적을 받아보세요!`,
          noPaidOffer: false,
        },
      ],
      currentKey: 0,
    }

    return {
      init: false,
      listData: {
        0: [],
        1: [],
        // 2: [],
      },
      offset: {
        listData: [0, 0],
      },
      que: {
        listData: [false, false],
      },
      scroll: {
        lastY: [0, 0],
      },
      nav,
      history: {
        exit: false,
      },
    }
  },
  created() {
    this.$eventBus.$on('refresh', route => {
      if (route.path === '/client/my-offer') {
        this.$nextTick()
          .then(() => {
            this.$refs?.container?.scrollTo?.({ top: 0 })
          })
          .then(() => {
            this.getData(true)
          })
      }
    })

    this.getData()
  },
  mounted() {
    this.$refs.container.addEventListener('scroll', this.getDataOnScroll, false)
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      const navNextKey = Vue.$route.query?.nav
      if (navNextKey) {
        Vue.nav.currentKey = Number(navNextKey)
      }

      const lastY = Vue.scroll.lastY[Vue.nav.currentKey]
      const init = Vue.listData[Vue.nav.currentKey].length > 0

      if (init) {
        Vue.$refs.container.scrollTop = lastY
      } else {
        Vue.getData(true)
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (this.$store.state.history.back) {
        this.$runToApp({
          androidCb: () => {
            if (!this.history.exit) {
              this.$toast('뒤로 버튼을 한번 더 누르시면 종료됩니다.')
              this.history.exit = true
              setTimeout(() => (this.history.exit = false), 2000)
            } else {
              location.href = 'hbscheme://appExit'
            }
            next(false)
          },
          iosCb: () => next(false),
          webCb: () => next(false),
        })
      } else {
        this.scroll.lastY[this.nav.currentKey] = this.$refs.container.scrollTop
        next()
      }
    })
  },
  methods: {
    getData(init) {
      if (this.nav.list[this.nav.currentKey].type === 'offer') {
        this.getOfferData(init)
      } 
    //   else {
    //     this.getArtistData()
    //   }
    },
    getOfferData(init) {
      if (this.que.listData[this.nav.currentKey]) return
      this.que.listData[this.nav.currentKey] = true

      if (init) this.offset.listData[this.nav.currentKey] = 0

      const req = {
        method: 'post',
        url: `/client/myOffer/${this.offset.listData[this.nav.currentKey]}/20`,
        data: {
          mode: '',
          profile: 'y',
          proPrice: 'n',
          nowState: this.nav.list[this.nav.currentKey].nowState,
        },
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let offerData = data.response.offerData || []
            this.listData[this.nav.currentKey] = init ?  offerData : this.listData[this.nav.currentKey].concat(offerData)
            this.offset.listData[this.nav.currentKey] += offerData.length
          } else {
            this.$alert(resultData)
          }
          return
        })
        .catch(e => {
          log.error(e)
          return
        })
        .then(() => {
          this.init = true
          setTimeout(() => {
            this.que.listData[this.nav.currentKey] = false
          }, 1000)
          return
        })
    },

    // TODO 관심 목록 조회 메뉴 -> 바텀 메뉴로 이동
    // getArtistData() {
    //   if (this.que.listData[this.nav.currentKey]) return
    //   this.que.listData[this.nav.currentKey] = true

    //   const req = {
    //     method: 'get',
    //     url: '/client/fav',
    //   }

    //   this.$http(req)
    //     .then(({ data }) => {
    //       log(...Object.values(req), data)

    //       const resultData = this.$lib.resultCheck(data.resultData)

    //       if (resultData.success) {
    //         const artistData = data.response?.artistData || []
    //         this.listData[this.nav.currentKey] = artistData
    //       } else {
    //         this.$alert(resultData)
    //       }
    //     })
    //     .catch(e => {
    //       log.error(e)
    //       return
    //     })
    //     .then(() => {
    //       this.init = true
    //       setTimeout(() => {
    //         this.que.listData[this.nav.currentKey] = false
    //       }, 1000)
    //       return
    //     })
    // },

    getDataOnScroll() {
      if (['ClientMyOffer', 'ArtistMyOffer'].indexOf(this.$route.name) === -1) return

      let scrollTop = this.$refs.container.scrollTop
      let scrollHeight = this.$refs.container.scrollHeight

      if (scrollHeight <= scrollTop + window.innerHeight) {
        if (this.nav?.list?.[this.nav?.currentKey]?.type === 'offer') {
          this.getData()
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
  .offer-list {
    padding: 0 3rem;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
